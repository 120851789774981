import { RootState } from '../app.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IConfigurationState {
  searchUser: Record<string, any>;
}

export const initialState: IConfigurationState = {
  searchUser: {},
};

export const searchUserSlice = createSlice({
  name: 'searchUser',
  initialState,
  reducers: {
    setSearchUser: (state, action: PayloadAction<any>) => {
      state.searchUser = action.payload;
    },
    clearSearchUser: (state) => {
      state.searchUser = {};
    },
  },
});

// A selector
export const searchUserSelector = (state: RootState) => state.searchUser.searchUser;

// Actions
export const { setSearchUser, clearSearchUser } = searchUserSlice.actions;

// The reducer
export default searchUserSlice.reducer;

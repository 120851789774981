export enum AppRoutings {
  SignIn = '/sign-in',
  SSO = '/SSO',
  SSOlogin = '/SSO-login',
  DashboardRental = '/dashboard-rental',
  PageNotFound = '/404',
  Image = '/image',
  PublicImages = '/im_view',
  PublicImagesOld = '/cust_photos', // TODO: figure out how to phase this out
  PublicImagesMigration = '/pubim', // TODO: phase this out on server side
  PublicDamages = '/dmg_view',
  SpamURLS = '/*',
  WebRoot = '/',

  // General
  WebApp = '/webapp',
  Settings = '/settings',
  Admin = '/admin',
  CreateLocation = '/admin/locations/new',
  EditLocation = '/admin/locations/edit/:id',
  UserManagement = '/user-management',
  CreateUser = '/user-management/users/new',
  EditUser = '/user-management/users/edit/:id',
  QueryUser = '/user-management/users/query',
  DisableUsers = '/user-management/disable_users',

  // Reviews
  Reviews = '/reviews',
  DamageOverview = '/reviews/overview/damage-details/:id',
  DamageDetails = '/reviews/overview/:id',
  DamageAnnotationOverview = '/annotations',
  DamageAnnotation = '/annotations/damage-details/:id',
  Gantry = '/reviews/gantry/:id',

  // New Policies
  NewPolicies = '/newpolicies',
  NewClaims = '/newclaims',

  // Locations
  Locations = '/locations',

  // Assets
  Assets = '/assets',
  AssetDetails = '/assets/details/:id',
  EditAssetDetails = '/assets/details/edit/:id',
  AssetCondition = '/assets/condition/:id',

  // Scans
  Scans = '/scans',
  ScansDetails = '/scans/details/:id',
  EditScansDetails = '/scans/details/edit/:id',
  ScansReview = '/scans/review/:id',
  ScansReviewList = '/scansreviewlist',
  ScansView = '/scans/view/:id',

  // One time user: request scan
  RequestScan = '/requestscan',

  // Fraud check
  CaptureCheck = '/capturecheck',
  CaptureCheckDetails = '/capturecheck/details/:id',

  // Expo
  Scoreboard = '/expo/scoreboard',

  //Claims
  DashboardClaims = '/dashboard-claims',
  Claims = '/claims',
  ClaimsDetails = '/claims/details/:id',
  LabelGroup = '/claims/label-group/:id',
  SmartAssessment = '/claims/smart-assessment/:id',
  SmartAssessmentNext = '/claims/smart-assessment-next/:id',
  RequestView = '/claims/request-view/:id',
  RepairRequests = '/repair-requests',
  RequestForRepairer = '/repair-requests/view-repairer/:id',
  CreateQuote = '/repair-requests/create-quote',
  ReviewQuote = '/repair-requests/review-quote',
  BatchUpload = '/batch-upload',
  WorkshopFinder = '/workshop-finder',
  WorkshopUpload = '/workshop-upload',
  Workshops = '/workshops',

  // Car dealers
  CarDealerValuationCarOwnerFirstOffer = '/cd-instant-offer',

  // Temporary: scan comparison
  Comparisons = '/comparisons',
  ComparisonsDealers = '/comparisonsdealers',
  ComparisonReports = '/comparisonreports',
  ScanComparisonDetails = '/comparisons/details/:id',
  Preview = '/comparisons/preview/:id',
}

import moment from 'moment';
import { DATE_FORMAT, KEY_LS_ALLOWED_SITES, SEPARATOR_COGNITO_ALLOWEDSITES } from './constants';

export const updateLocationsInLocalStorage = (csvSites: string): void => {
  const result = {}; // Default: no credentials
  if (csvSites != null && csvSites.length > 0) {
    const existing = loadCheckedLocationsCache(false);
    const sites = csvSites.split(SEPARATOR_COGNITO_ALLOWEDSITES);

    // Only include from existing if the key is in sites
    for (const k of Object.keys(existing)) {
      if (sites.includes(k)) {
        result[k] = existing[k];
      }
    }

    // Add items from sites if the key is not in existing (updated)
    for (const site of sites) {
      if (!(site in result)) {
        result[site] = { show: true };
      }
    }
  } else {
    // No credentials
  }
  // Local storage can't handle objects: stringify
  localStorage.setItem(KEY_LS_ALLOWED_SITES, JSON.stringify(result));
};

export const loadCheckedLocationsCache = (selectedOnly: boolean): object => {
  const sitesRaw = localStorage.getItem(KEY_LS_ALLOWED_SITES);
  let sites = {};
  if (sitesRaw != null && sitesRaw.length > 0) {
    sites = JSON.parse(sitesRaw);
  }

  if (selectedOnly) {
    Object.keys(sites).forEach((key) => {
      if (!sites[key]['show']) {
        delete sites[key];
      }
    });
  }
  return sites;
};

export const setSignature = (signature: string) => {
  const objSig = JSON.parse(signature);
  if (objSig.signtime != null) {
    return moment(objSig.signtime, 'YYYYMMDDHHmmss').format(DATE_FORMAT);
  }
};

export const extractDateFromAWSDateTime = (awsDateTime) => {
  return awsDateTime.split('T')[0];
};
